.page-blog {
}

.blog-item {
    border-top: 1px solid rgba(0, 0, 0, 0.117647);
    margin-top: 70px;
    padding: 70px 0 10px;
    &:first-child {
        border-top: 0;
        padding-top: 0;
        margin-top: 0;
    }

    h2 {
        font-size: 35px;
        line-height: 1;
        a {
            color: $text-color;
            font-weight: $font-weight-thin;
            text-decoration: none;
        }
    }

    .blog-info {
        margin: 10px 0;

        > span {
            margin-right: 8px;
        }

        .avatar {
            width: 30px;
            height: auto;
            border-radius: 50%;
        }

        .date {
            opacity: .7;
        }

        .category {
            display: inline-block;
            text-transform: uppercase;
            font-size: 12px;
            padding: 2px 5px;
            border-radius: $border-radius-base;
            background-color: rgba(#000, .08);

            a {
                color: $text-color;
                text-decoration: none;
                opacity: .7;
            }
        }
    }

    .desc {
        font-size: 16px;
        opacity: .7;
    }
}



.theme-gray,
.theme-dark {
    .blog-item {
        h2 a {
            color: $theme_dark_text_color;
        }
        .blog-info {
            .category a {
                color: $theme_dark_text_color;
            }
        }
    }
}
